import {useSelector} from 'react-redux';
import './App.css';
import {useState} from 'react';
import {
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

function DnsAnswerTableComponent() {
    const dnsDataResponse = useSelector((state) => state.dnsData);

    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = async (data) => {
        try {
            await navigator.clipboard.writeText(data);
            setCopySuccess('Copied!');
            setTimeout(() => {
                setCopySuccess('');
            }, 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setCopySuccess('Failed to copy.');
        }
    };

    const renderTable = () => {
        if (!dnsDataResponse || !dnsDataResponse.records) {
            return null;
        }

        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <div style={{textAlign: 'center'}}>
                    <Typography variant="h5" component="div" gutterBottom>
                        DNS RECORD
                    </Typography>
                    <TableContainer component={Paper} elevation={0}
                                    style={{backgroundColor: 'transparent', display: 'inline-table'}}>
                        <Table style={{tableLayout: 'auto', width: 'auto'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{whiteSpace: 'normal', textAlign: 'center'}}>
                                        {dnsDataResponse.record_type}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dnsDataResponse.records.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{whiteSpace: 'normal'}}>{item}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{marginTop: '20px'}}>
                        <Button variant="contained" color="primary"
                                onClick={() => copyToClipboard(dnsDataResponse.records.join('\n'))}>
                            Copy
                        </Button>
                    </div>
                    <Typography variant="body1" component="p">
                        {copySuccess}
                    </Typography>
                </div>
            </div>
        );
    };


    const isInvalidDomain = () => {
        return dnsDataResponse.status === 'invalid';
    };

    const isErrorStatus = () => {
        return dnsDataResponse.status === 'error';
    };

    if (!dnsDataResponse || typeof dnsDataResponse === 'string') {
        return <Typography color="textSecondary">{dnsDataResponse}</Typography>;
    } else if (isInvalidDomain() || isErrorStatus()) {
        return <Typography color="textSecondary">{dnsDataResponse.message}</Typography>;
    } else {
        return (
            <div>
                <div className="table-container">{renderTable()}</div>
                {dnsDataResponse.TTL && (
                    <Typography color="textSecondary">
                        <strong>TTL:</strong> {dnsDataResponse.TTL}
                    </Typography>
                )}
            </div>
        );
    }
}

export default DnsAnswerTableComponent;
