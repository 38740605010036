import { useState } from 'react';
import { API_GATEWAY_BASE_URL } from './config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateDnsData } from './dnsStore';
import { ExpandMore } from '@mui/icons-material';
import { TextField, Button, Select, MenuItem, CircularProgress } from '@mui/material';

function DnsSearchQueryForm() {
    const dnsQueryEndpoint = '/api/dns-query'
    const regex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    const [recordType, setRecordType] = useState('A RECORD');
    const [domainAddress, setDomainAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [inputError, setInputError] = useState(null);
    const dispatch = useDispatch();

    // Validate input if it's a domain, else set it to null
    const handleInputChange = (event) => {
        const value = event.target.value;
        if (regex.test(value)) {
            setDomainAddress(value);
        } else {
            setDomainAddress(value);
        }
    };

    // Handle record type to search
    const handleRecordTypeChange = (event) => {
        setRecordType(event.target.value);
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const requestBody = {
            domainName: domainAddress,
            recordType: [recordType],
        };

        try {
            const response = await axios.post(`${API_GATEWAY_BASE_URL}${dnsQueryEndpoint}`, JSON.stringify(requestBody), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status === 'invalid') {
                setInputError(response.data.message);
            } else {
                setInputError(null); // clear any previous error
                dispatch(updateDnsData(response.data));
                // dispatch(updateDnsData({data: response.data, domain: domainAddress}));

            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setInputError('Failed to fetch data.');
        } finally {
            setIsLoading(false);
        }
    };

    // Render the form component
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    name="DNS_QUERY"
                    label="Domain"
                    variant="outlined"
                    size="large"
                    onChange={handleInputChange}
                    placeholder="example.com"
                    InputProps={{
                        style: { color: 'white' },
                    }}
                />
                {inputError && <span style={{ color: 'red', marginLeft: '10px' }}>{inputError}</span>}
                <Select value={recordType} onChange={handleRecordTypeChange} IconComponent={ExpandMore}
                    sx={{ color: 'white', marginLeft: '10px' }}>
                    <MenuItem value="A RECORD">A RECORD</MenuItem>
                    <MenuItem value="MX RECORD">MX RECORD</MenuItem>
                    <MenuItem value="SPF RECORD">SPF RECORD</MenuItem>
                    <MenuItem value="DKIM RECORD">DKIM RECORD</MenuItem>
                </Select>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{
                        color: 'white',
                        width: '100px',
                        marginLeft: '10px',
                    }}
                >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : 'Query'}
                </Button>
            </form>
        </div>
    );
}

export default DnsSearchQueryForm;
