// import React from 'react';
// import { Provider } from 'react-redux';
// import store from './dnsStore';
// import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
// import DnsAnswerTableComponent from './dnsTableResponse';
// import UserCard from './userVisitPublicIP';
// import DnsSearchQueryForm from './dnsQueryInputBar';
// import EmailHeaderAnalyzer from './emailAnalyzer';
// import NavigationBar, { HomePage } from './NavigationBar';
//
//
// const router = createBrowserRouter([
//     {
//         path: '/dns-query',
//         element: (
//             <>
//                 <UserCard />
//                 <DnsSearchQueryForm />
//                 <DnsAnswerTableComponent />
//             </>
//         ),
//     },
//     { path: '/email-analyzer', element: <EmailHeaderAnalyzer /> },
//     { path: '/', element: <HomePage /> }
// ]);
//
//
// function App() {
//     return (
//         <Provider store={store}>
//             <div className="App">
//                 <header className="App-header">
//                     <RouterProvider router={router}>
//                         <NavigationBar />
//                         <Route path="/dns-query" element={<>
//                             <UserCard />
//                             <DnsSearchQueryForm />
//                             <DnsAnswerTableComponent />
//                         </>} />
//                         <Route path="/email-analyzer" element={<EmailHeaderAnalyzer />} />
//                         <Route path="/" element={<HomePage />} />
//                     </RouterProvider>
//                 </header>
//             </div>
//         </Provider>
//     );
// }
//
// export default App;
import React from 'react';
import {Outlet} from 'react-router-dom';
import NavigationBar from './NavigationBar';

export default function App() {
    return (
        <div>
            <NavigationBar/>
            <hr/>
            <Outlet/>
        </div>
    );
}