import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import UserCard from '../userVisitPublicIP';
import DnsSearchQueryForm from '../dnsQueryInputBar';
import DnsAnswerTableComponent from '../dnsTableResponse';
import EmailHeaderAnalyzer from '../emailAnalyzer';
import { HomePage } from '../NavigationBar';


const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            { index: true, element: <HomePage /> },
            {
                path: 'dns-query',
                element: (
                    <>
                        <UserCard />
                        <DnsSearchQueryForm />
                        <DnsAnswerTableComponent />
                    </>
                ),
            },
            { path: 'email-analyzer', element: <EmailHeaderAnalyzer /> },
        ],
    },
]);

export default router;