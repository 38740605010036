import React, {useState} from 'react';
import axios from 'axios';
import {API_GATEWAY_BASE_URL} from './config';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextareaAutosize, Typography
} from '@mui/material';


const EmailHeaderAnalyzer = () => {
    const [headers, setHeaders] = useState('');
    const [analysis, setAnalysis] = useState(null);
    const [error, setError] = useState(null);
    const emailHeaderEndpoint = '/api/analyze/email';

    const analyzeHeaders = async () => {
        try {
            const response = await axios.post(`${API_GATEWAY_BASE_URL}${emailHeaderEndpoint}`, {
                headers: headers.trim(),
            });
            setAnalysis(response.data);
            setError(null);
        } catch (error) {
            console.error('Error analyzing headers:', error);
            if (error.response && error.response.data) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred during analysis.');
            }
        }
    };

    return (<div style={{textAlign: 'center'}}>
        <TextareaAutosize
            value={headers}
            onChange={(e) => setHeaders(e.target.value)}
            placeholder="Paste email headers here"
            style={{width: '1000px', height: '300px', display: 'block', margin: '0 auto'}}
        />
        <Button
            variant="contained"
            onClick={analyzeHeaders}
            style={{display: 'block', margin: '20px auto'}}
        >
            Analyze Headers
        </Button>

        {error && (<div style={{marginTop: '10px'}}>
            <Typography variant="h6" color="error">Error:</Typography>
            <Typography>{error}</Typography>
        </div>)}

        {analysis && (
            <TableContainer component={Paper} style={{marginTop: '20px'}}>
                <Table size="small" style={{fontSize: '0.8rem'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{
                                padding: '4px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                backgroundColor: '#4caf50',
                                color: 'white',
                                whiteSpace: 'nowrap' // Prevent text wrapping
                            }}>
                                Header Name
                            </TableCell>
                            <TableCell style={{
                                padding: '4px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                backgroundColor: '#4caf50',
                                color: 'white',
                                whiteSpace: 'nowrap' // Prevent text wrapping
                            }}>
                                Header Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {analysis.map((header, index) => (
                            <TableRow
                                key={index}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#f0f0f0', // slightly darker zebra striping
                                    '&:hover': {
                                        backgroundColor: '#d0d0d0', // row hover background color
                                    }
                                }}
                            >
                                <TableCell style={{
                                    padding: '2px 4px',
                                    fontSize: '0.8rem',
                                    color: 'black'
                                }}>{header.name}</TableCell>
                                <TableCell style={{
                                    padding: '2px 4px',
                                    fontSize: '0.8rem',
                                    color: 'black'
                                }}>{header.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )}
    </div>);
};

export default EmailHeaderAnalyzer;
