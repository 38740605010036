import axios from 'axios';
import {useState, useEffect} from 'react';

function UserCard() {
    const [ip, setIp] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('https://api.ipify.org?format=json')
            .then(response => {
                setIp(response.data.ip);
            })
            .catch(error => {
                console.log('Error fetching IP address', error);
                setError('Error fetching IP address');
            });
    }, []);

    return (
        <div>
            {ip && <p>Public IP: {ip}</p>}
            {error && <p>{error}</p>}
        </div>
    );
}

export default UserCard;