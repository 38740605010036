import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const HomePage = () => (
    <div>
        <h1>Welcome to the homepage!</h1>
    </div>
);

const NavigationBar = () => (
    <AppBar position="static">
        <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
                DNS Insights
            </Typography>
            <Button color="inherit" component={RouterLink} to="/">
                Home
            </Button>
            <Button color="inherit" component={RouterLink} to="/dns-query">
                DNS Query
            </Button>
            <Button color="inherit" component={RouterLink} to="/email-analyzer">
                Email Header Analyzer
            </Button>
        </Toolbar>
    </AppBar>
);

export default NavigationBar;
