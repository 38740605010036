import { configureStore, createSlice } from '@reduxjs/toolkit';


// Create a slice for the DNS data, and what actions should be taken when its updated
const dnsDataSlice = createSlice({
  name: 'DNS Data',
  initialState: {},
  reducers: {
    updateDnsData: (state,action)=> {
      //state.dnsData = JSON.stringify(action.payload);
      state.dnsData = action.payload;
    }
  }
});


const store = configureStore({
  reducer: dnsDataSlice.reducer
});

export const { updateDnsData } = dnsDataSlice.actions;

export default store;




